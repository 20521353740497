import React from 'react'

import { Link } from 'gatsby'

const Header = ({ darkMode, toggleDarkMode, layout }) => (
  <div className="navbar bg-base-100">
    <div className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center space-x-4">
          <Link
            to="/"
            aria-label="home"
            className="text-lg font-medium text-primary hover:text-primary-focus"
          >
            dusanstam.com
          </Link>
        </div>
        <button
          className="btn btn-ghost btn-sm text-lg font-medium text-primary hover:text-primary-focus"
          onClick={toggleDarkMode}
          aria-label="toggle theme"
        >
          {darkMode ? 'Light' : 'Dark'}
        </button>
      </div>
    </div>
  </div>
)

export default Header
