import React, { useEffect, useState } from 'react'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, pageContext: { layout } }) => {
  const [darkMode, setDarkMode] = useState(false)
  const [themeChecked, setThemeChecked] = useState(false)

  useEffect(() => {
    // get dark mode from local storage
    const darkModeLS = JSON.parse(localStorage.getItem('dusanstam_dark_mode'))
    // get system theme
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

    if (darkModeLS === null) {
      // if dark mode is not set in local storage, set it to system theme
      setDarkMode(systemTheme === 'dark')
    } else {
      setDarkMode(darkModeLS || false)
    }

    setThemeChecked(true)
  }, [])

  useEffect(() => {
    if (!themeChecked) return

    const html = document.querySelector('html')
    if (html) {
      html.dataset.theme = darkMode ? 'dark' : 'light'
    }
  }, [darkMode, themeChecked])

  const toggleDarkMode = () => {
    localStorage.setItem('dusanstam_dark_mode', !darkMode)
    setDarkMode(!darkMode)
  }

  return (
    <div className="mx-4 flex h-screen max-w-4xl flex-col justify-between lg:m-auto">
      <div>
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} layout={layout} />
        <div className="my-10">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
