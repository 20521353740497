import React from 'react'

const Footer = () => (
  <footer className="bg-base-100">
    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <div className="flex flex-col justify-between sm:flex-row">
        <a
          href="https://github.com/dstamenkovic"
          className="hover:text-primary-focus text-lg font-medium text-primary"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="GitHub"
        >
          GitHub
        </a>

        <a
          href="https://www.linkedin.com/in/dusan-stamenkovic"
          target="_blank"
          className="hover:text-primary-focus text-lg font-medium text-primary"
          rel="noreferrer noopener"
          aria-label="LinkedIn Profile"
        >
          LinkedIn
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
